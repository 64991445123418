<template>
    <b-card>
        <div class="text-right">
            <b-button size="sm" variant="warning" @click="goToHome"> <b-icon-arrow-left /> Regresar</b-button>
        </div>
        <b-overlay :show="isSavingPerformanceConcept || isLoadingData" rounded class="">
            <validation-observer v-slot="{ invalid }" ref="PerformanceConceptCreation">
                <b-form @submit.prevent="saveConfiguration">
                    <b-row  class="justify-content-center">
                        <b-col>
                            <b-form-group label="Año">
                                <b-form-select
                                    v-model="year"
                                    :disabled="disableFields"
                                >
                                <option :value="null">Seleccione una opción</option>
                                <option v-for="year in listyears" :key="year" :value="year">{{ year }}</option>
                                </b-form-select>
                            </b-form-group>
                        </b-col>

                        <b-col>
                            <validation-provider name="Hotel" rules="required">
                                <b-form-group slot-scope="{ valid, errors }" label="Hotel">
                                    <b-form-select
                                        :state="errors[0] ? false : valid ? true : null"
                                        v-model="selectedHotel"
                                        :clearable="false"
                                        :disabled="disableFields"
                                    >
                                        <option :value="null" disabled>Seleccione una opción</option>
                                        <option v-for="h in hotels" :key="h.id" :value="h.id">{{ h.name }}</option>
                                    </b-form-select>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <b-col>
                            <validation-provider name="Conceptos" rules="required">
                                <b-form-group slot-scope="{ valid, errors }" label="Conceptos">
                                    <b-form-select
                                        :state="errors[0] ? false : valid ? true : null"
                                        v-model="selectConcepts"
                                        :clearable="false"
                                        :disabled="disableFields"
                                    >
                                        <option :value="null" disabled>Seleccione una opción</option>
                                        <option v-for="item in catalogPerformanceConcepts" :key="item.id" :value="item.id">{{ item.name }}</option>
                                    </b-form-select>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <b-col>
                            <b-form-group>
                                <label>Valor</label>
                                <input
                                    v-model="value"
                                    type="text"
                                    class="form-control"
                                    :disabled="disableFields"
                                    @keypress="onlyNumberWithDecimal"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <ValidationProvider rules="" name="status">
                                <b-form-group>
                                    <label>Status</label>
                                    <b-form-checkbox v-model="status" switch />
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>

                    <hr>

                    <b-button
                        variant="primary"
                        type="submit"
                        class="float-right "
                        :disabled="invalid || isSavingPerformanceConcept"
                    >Guardar
                    </b-button>
                </b-form>
            </validation-observer>
        </b-overlay>
    </b-card>
</template>

<script>
import { formatDateOnly, validYears } from "@/helpers/helpers";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import { utils } from "@/modules/fivesClub/mixins/utils"
import { fetchHotels } from '@/modules/start/store/actions'
import Filters from '@/modules/fivesClub/components/profit/performanceConcepts/Filters'
import PaginationTable from '@/modules/fivesClub/components/profit/costs/PaginationTable'
import Ripple from 'vue-ripple-directive'

export default {
    mixins: [utils],
    directives: { Ripple, },
    components:{
        PaginationTable,
        Filters,
    },
    data() {
        return {
            isSavingPerformanceConcept: false,
            isLoadingData: false,
            dataConfiguration: null,
            modalVisible: false,
            performanceId: null,
            year: null,
            selectedHotel: null,
            isNew: true,
            status:false,
            selectConcepts: null,
            value: 0,
            disableFields: false,
        };
    },
    async mounted() {
        await this.init()
    },
    computed: {
        ...mapState('fivesClubProfit',['performanceConcept','catalogPerformanceConcepts']),
        ...mapState('start',['hotels']),

        listyears(){
            return validYears()
        },
    },
    methods: {
        ...mapMutations('start',['setHotels']),
        ...mapActions('fivesClubProfit', ['fetchCatalogPerformanceConcept','savePerformanceConfigutation','updatePerformanceConfigutation']),
        ...mapMutations('fivesClubProfit',['setPerformanceConcepts', 'setCatalogPerformanceConcepts']),
        async init() {
            this.isLoadingData = true
            if( this.hotels.length === 0 ){
                const payloadHotels = { onlyDingusCode: false, all: false, showInWeb: true }
                const hotels = await fetchHotels(payloadHotels)
                this.setHotels(hotels)
            }
            const concepts = await this.fetchCatalogPerformanceConcept()
            this.setCatalogPerformanceConcepts(concepts)

            if (this.$route.params.hotelId || this.$route.params.year) {
                this.selectedHotel = this.$route.params.hotelId || null
                this.year = this.$route.params.year || null
            }

            this.isLoadingData = false
        },
        async saveConfiguration() {
            const isConfirmed = await this.confirmSaving()
            if (isConfirmed) {
                this.isSavingPerformanceConcept = true
                const payload = {
                    performanceId: this.performanceId,
                    conceptsId: this.selectConcepts,
                    hotelId: this.selectedHotel,
                    year: this.year,
                    value: this.value,
                    status: this.status
                }

                const response = await this.savePerformanceConfigutation(payload)

                if (response?.status) {
                    this.isSavingPerformanceConcept = false
                    this.goToHome({
                        reload: true,
                        hotelId: payload.hotelId,
                        year: payload.year,
                    })
                }
                this.isSavingPerformanceConcept = false

            }
        },
        async confirmSaving() {
            const { isConfirmed } = await this.$swal.fire({
                title: "¿Deseas guardar la información?",
                text: "Favor de confirmar",
                showDenyButton: true,
                confirmButtonText: "Sí, guardar",
                denyButtonText: "Cancelar",
                customClass: {
                    confirmButton: 'swal-button-yes',
                    denyButton: 'swal-button-cancel',
                },
            });
            return isConfirmed
        },
        goToHome(params){
            this.$router.push({ name: 'concept-config', params })
        },
    },
};
</script>


<style lang="scss" >
@media (max-width: 1280px) {
    .mb-0.table-responsive {
        max-height: 369px;
    }
    .table-responsive th {
        padding: 10px 10px 10px 10px !important;
        font-size: 10px;
    }
    .table-responsive th div {
        font-size: 10px;
    }
    .table-responsive td {
        padding: 5px !important;
        font-size: 11px;
    }
    .buttonInfo button {
        padding: 9px;
    }

    .buttonInfo {
        display: table-cell;
    }
}

.swal-button-yes {
    background-color: #3085d6;
    color: #fff;
    margin-right: 10px;  /* Ajusta la separación a tu preferencia */
}

/* Estilo para el botón de cancelación */
.swal-button-cancel {
    background-color: #d33;
    color: #fff;
}
</style>
